// Configuration
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// Variables
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

$sizes: (
  auto: auto,
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
);

$paddings: (
  xs: 18px,
  sm: 15px,
  md: 24px,
  lg: 24px,
  xl: 24px,
  xxl: 24px,
);

// Layout & components
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/containers';

// Helpers
@import '~bootstrap/scss/helpers';

// Utilities
@import '~bootstrap/scss/utilities/api';

// Custom utilities
@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size, $length in $sizes {
    @include media-breakpoint-up($breakpoint) {
      .w-#{$breakpoint}-#{$size} {
        width: $length !important;
      }
    }
  }
}

// Override container class with custom gutters
@mixin make-container($padding-x: $container-padding-x) {
  margin-right: auto;
  margin-left: auto;

  @each $breakpoint in map-keys($grid-breakpoints) {
    @each $size, $length in $paddings {
      @if $size == $breakpoint {
        @include media-breakpoint-up($breakpoint) {
          padding-left: $length !important;
          padding-right: $length !important;
        }
      }
    }
  }
}

.container {
  @include make-container();
}
